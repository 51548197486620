import React from 'react';
import ReactDOM from 'react-dom';

import PreLoading from './components/loading/preLoading';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'cotizador-component';

const LazyApp = React.lazy(() => import("./App"));

ReactDOM.render(
    <React.Suspense fallback={<PreLoading />}>
        <LazyApp />
    </React.Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
